<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ parentObj._id ? `Add Industry in ${parentObj.name}` : "Add New Industry" }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Category Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="industryData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category Arabic Name -->
          <validation-provider #default="validationContext" name="arabicName" rules="required">
            <b-form-group label="Arabic Name" label-for="arabicName">
              <b-form-input
                id="arabicName"
                v-model="industryData.arabicName"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category Slug -->
          <validation-provider #default="validationContext" name="slug" rules="required">
            <b-form-group label="Slug" label-for="slug">
              <b-form-input
                id="slug"
                v-model="industryData.slug"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider #default="validationContext" name="Is Active" rules="required">
            <b-form-group
              label="Is Active"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="industryData.isActive"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Display Order">
            <b-form-group
              label="Display Order"
              label-for="displayOrder"
              :state="getValidationState(validationContext)"
            >
              <b-form-spinbutton v-model="industryData.displayOrder" />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch, computed } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";

import useIndustriesList from "./useIndustriesList";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSpinbutton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewSidebarActive",
    event: "update:is-add-new-sidebar-active",
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    parentObj: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const { addIndustry } = useIndustriesList();

    const blankIndustryData = {
      name: "",
      slug: "",
      isActive: true,
      displayOrder: 0,
    };
    const industryData = ref(JSON.parse(JSON.stringify(blankIndustryData)));
    const resetIndustryData = () => {
      industryData.value = JSON.parse(JSON.stringify(blankIndustryData));
    };

    const onSubmit = async () => {
      const finalData = industryData.value;
      finalData.parentId = props.parentObj._id || null;

      const response = await addIndustry(finalData);

      if (typeof response !== "undefined") {
        emit("update:is-add-new-sidebar-active", false);
        emit("refetch-data");
      }
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetIndustryData);

    return {
      industryData,
      onSubmit,
      addIndustry,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
