<template>
  <div id="industries">
    <industries-add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :statuses="statuses"
      :parent-obj.sync="parent"
      @refetch-data="refetchData"
    />

    <b-card class="mb-0" body-class="p-0">
      <div class="m-2">
        <b-row class="align-items-center">
          <b-col cols="12" md="6">
            <h2>{{ parent.name }}</h2>
          </b-col>
          <b-col cols="23" md="6" class="text-right">
            <b-button
              @click="changeParent(parent.parentId ? parent.parentId : 0)"
              variant="success"
              :disabled="!parent._id"
              class="mr-1"
            >
              <feather-icon icon="ArrowLeftIcon" />
              Back
            </b-button>
            <b-button variant="primary" @click="isAddNewSidebarActive = true">{{
              parent._id ? `Add in ${parent.name}` : `Add New Industry`
            }}</b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="fetchIndustries"
        ref="refIndustriesTable"
        class="position-relative"
        :fields="tableColumns"
        show-empty
        :empty-html="isLoading ? 'Loading...' : 'No Data'"
      >
        <template #cell(name)="data">
          <b-button @click="changeParent(data.item._id)" variant="link">{{
            data.item.name
          }}</b-button>
        </template>
        <template #cell(slug)="data">{{ data.item.slug }}</template>
        <template #cell(displayOrder)="data">
          <b-form-spinbutton
            v-model="data.item.displayOrder"
            :value="data.item.displayOrder"
            @change="changeDisplayOrder(data.item._id, $event)"
          ></b-form-spinbutton>
        </template>
        <template #cell(isActive)="data">
          <b-form-checkbox
            switch
            v-model="data.item.isActive"
            @change="changeStatus(data.item._id, $event)"
            class="custom-control-success"
          />
        </template>
        <template #cell(actions)="data">
          <b-button size="sm" :data-test="data" variant="warning">Edit</b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormSpinbutton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useIndustriesList from "./useIndustriesList";
import IndustriesAddNew from "./IndustriesAddNew";
import { ref, onUnmounted } from "@vue/composition-api";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSpinbutton,
    IndustriesAddNew,

    vSelect,
  },
  setup() {
    const isAddNewSidebarActive = ref(false);

    const {
      tableColumns,
      refIndustriesTable,
      parentID,
      fetchIndustries,
      resolveIndustryStatusLabel,
      parent,
      changeParent,
      changeDisplayOrder,
      changeStatus,
      statuses,
      refetchData,
      isLoading,
    } = useIndustriesList();

    return {
      tableColumns,
      refIndustriesTable,
      parentID,
      fetchIndustries,
      resolveIndustryStatusLabel,
      parent,
      changeParent,
      changeDisplayOrder,
      changeStatus,
      isAddNewSidebarActive,
      statuses,
      refetchData,
      isLoading,
    };
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
