import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function useIndustriesList() {
  const toast = useToast();

  const parentID = ref(0);
  const parent = ref({
    name: "Industries",
  });
  const refIndustriesTable = ref(null);
  const isAddNewSidebarVisible = ref(false);
  const isLoading = ref(false);

  const tableColumns = [
    { key: "name", sortable: false },
    { key: "arabicName", sortable: false, label: "arabic Name" },
    { key: "slug", sortable: false },
    { key: "displayOrder", sortable: false },
    { key: "isActive", sortable: false, label: "Status" },
    { key: "actions" },
  ];

  const fetchIndustries = async () => {
    try {
      isLoading.value = true;
      const response = await axios.get(`industries/${parentID.value}/children`);
      const { data } = response;
      parent.value = data.parent == null ? { name: "Industries" } : data.parent;
      return data.data;
    } catch (error) {
      if (error.response.status === 401) {
        toast({
          component: ToastificationContent,
          props: {
            title: "you dont have enough permisssion to make this action.",
            icon: "AlertTrainingIcon",
            variant: "danger",
          },
        });
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title:
              typeof error.response !== "undefined"
                ? error.response.data.error.message
                : error.message || "Unable to fetch the categories, please try again later.",
            icon: "AlertTrainingIcon",
            variant: "danger",
          },
        });
      }
    } finally {
      isLoading.value = false;
    }
  };

  const changeDisplayOrder = async (id, event) => {
    try {
      const formData = new FormData();
      formData.append("displayOrder", event);
      const response = await axios.put(`industries/updateOrder/${id}`, formData);
      refIndustriesTable.value.refresh();
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Unable to update Display Order , please try again later ",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const changeStatus = async (id, event) => {
    try {
      const formData = new FormData();
      formData.append("isActive", event);
      const response = await axios.put(`industries/updateOrder/${id}`, formData);
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Unable to update Display Order , please try again later ",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const addIndustry = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("arabicName", data.arabicName);
      formData.append("slug", data.slug);
      formData.append("isActive", data.isActive);
      formData.append("displayOrder", data.displayOrder);
      if (data.parentId != null) {
        formData.append("parentId", data.parentId);
      }

      const response = await axios.post("industries", formData);
      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new industry",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const refetchData = () => {
    refIndustriesTable.value.refresh();
  };

  const changeParent = (newParentID) => {
    parentID.value = newParentID;
  };

  const statuses = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  watch([parentID], () => {
    refetchData();
  });

  const resolveIndustryStatusLabel = (status) => {
    if (status == 1) return "success";
    return "danger";
  };

  return {
    refIndustriesTable,
    tableColumns,
    parentID,
    fetchIndustries,
    resolveIndustryStatusLabel,
    parent,
    changeParent,
    changeDisplayOrder,
    statuses,
    addIndustry,
    isAddNewSidebarVisible,
    refetchData,
    changeStatus,
    isLoading,
  };
}
